import axios from '../axios';

export const set = {

    dictData: [],

    setDictData(){
        axios.post("/system/dict/getDictData",{}).then(res=>{
            this.dictData = res.data
            localStorage.setItem('ssyn_dictData',JSON.stringify(res.data))
        })
    },
    getDictData(){
        if(!this.dictData || this.dictData.length === 0) {
            this.dictData = JSON.parse(localStorage.getItem('ssyn_dictData'))
        }
        return this.dictData
    }
}