import { set } from '@/api/set'
import store from '@/store/index'
export default {
    perValidate(code){
        let buttonScopeList = store.getters.getData('vuex_buttonScopeList')
        let flag = false
        for (let key in buttonScopeList) {
            if(buttonScopeList[key] === code) {
                flag = true
                break
            }
        }
        return flag
    },
    getDictData(code){
        let dict = set.getDictData().filter( item=> item.type === code )[0]
        if (dict) {
            let list = []
            dict.data.forEach(item=>{
                if (parseInt(item.code)) {
                    item.code = parseInt(item.code)
                }
                list.push(Object.assign({},item))
            })
            return list
        } else {
            return []
        }
    },
    getDictLabel(type,code){
        let dict = set.getDictData().filter( item=> item.type === type )[0]
        if (dict) {
            let obj = dict.data.filter( item=> item.code == code)[0]
            return obj ? obj.label : '未知'
        } else {
            return '未知'
        }
    }
}