import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from '@/store'
const routes = [
    {
        path: "/weatherDataShow",
        name: "weatherDataShow",
        component: ()=>import('@/views/equipShow/weatherDataShow'),
    },
    {
        path: "/soilDataShow",
        name: "soilDataShow",
        component: ()=>import('@/views/equipShow/soilDataShow'),
    },
    {
        path: "/insectDataShow",
        name: "insectDataShow",
        component: ()=>import('@/views/equipShow/insectDataShow'),
    },
    {
        path: "/insectDataBz",
        name: "insectDataBz",
        component: ()=>import('@/views/equipShow/insectDataBaozi'),
    },
    {
        path: "/bigDataLogin",
        name: "bigDataLogin",
        component: ()=>import('@/views/login/bigDataLogin'),
    },
    {
        path: "/equipShow",
        name: "equipShow",
        component: ()=>import('@/views/equipShow/home'),
    },
    {
        path: "/spjkgl",
        name: "spjkgl",
        component: ()=>import('@/views/equipShow/spjkgl'),
    },
    {
        path: "/qxInfo",
        name: "qxInfo",
        component: ()=>import('@/views/equipShow/qx'),
    },
    {
        path: "/bchInfo",
        name: "bchInfo",
        component: ()=>import('@/views/equipShow/bch'),
    },
    {
        path: "/nysc",
        name: "nysc",
        component: ()=>import('@/views/equipShow/nysc'),
    },
    {
        path: "/sfpt",
        name: "sfpt",
        component: ()=>import('@/views/equipShow/sfpt'),
    },
    {
        path: "/shhfw",
        name: "shhfw",
        component: ()=>import('@/views/equipShow/shhfw'),
    },
    {
        path: "/syxx",
        name: "syxx",
        component: ()=>import('@/views/equipShow/syxx'),
    },
    {
        path: "/",
        name: "azHome",
        component: ()=>import('@/views/az/home/index'),
    },
    {
        path: "/infoPub",
        name: "infoPub",
        redirect: 'infopub-home',
        component: ()=>import('@/views/az/infopub/home'),
        children: [
            {
                path: '/infopub-home',
                name: 'infopub-home',
                component: () => import('@/views/az/infopub/index'),
            },
            {
                path: "/goodsList",
                name: "goodsList",
                component: ()=>import('@/views/az/infopub/goodsList'),
            },
            {
                path: "/infopub-newsList",
                name: "infopub-newsList",
                component: ()=>import('@/views/az/infopub/newsList'),
            },
            {
                path: "/infopub-cgList",
                name: "infopub-cgList",
                component: ()=>import('@/views/az/infopub/cgList'),
            },
            {
                path: "/infopub-detail",
                name: "infopub-detail",
                component: ()=>import('@/views/az/infopub/detail'),
            },
            {
                path: "/infopub-newDetail",
                name: "infopub-newDetail",
                component: ()=>import('@/views/az/infopub/newsDetail'),
            },
            {
                path: "/infopub-form",
                name: "infopub-form",
                component: ()=>import('@/views/az/infopub/form'),
            },
        ]
    },
    {
        path: "/guidance",
        name: "guidance",
        component: ()=>import('@/views/az/expertguidance/main'),
    },
    {
        path: '/expertDetail',
        name: 'expertDetail',
        component: () => import('@/views/az/expertguidance/courseDetail'),
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

let flag = false
router.beforeEach(async (to, from, next)=>{
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //新增代码
    // if (to.matched.length === 0 ){
    //     next("/error_404")
    // }

    if (to.name === 'admin') {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
        }
        flag = true
        next()
    } else if (!flag) {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
            flag = true
            next({
                ...to,
                replace: true
            })
        } else {
            next()
        }
    } else {
        if (to.matched.length === 0 ){
            next("/error_404")
        }
        next()
    }
})

export function resetRouter(router) {

}

export default router
