export default {
    getPids(node){
        if (node.parent){
            return (this.getPids(node.parent) ? this.getPids(node.parent) + ',' : '') + node.data.id
        } else {
            return ""
        }
    },
    dedupe(array){
        return Array.from(new Set(array));
    }
}