<template>
  <div class="at-box">
    <div class="at-top-box flex">
      <div class="title-box flex align-center">
        <img src="~@/assets/img/box-top-icon-dark.png">
        <div class="title">{{title}}</div>
      </div>
      <div class="k"></div>
      <img src="~@/assets/img/k-icon.png" class="k-icon">
      <div class="k-line"></div>
    </div>
    <div class="slot">
      <slot></slot>
    </div>
    <div class="bottom-box flex align-center">
      <div class="p-box"></div>
      <div class="p-box" style="margin-left: 5px"></div>
      <div class="bottom-line"></div>
      <div class="p-box"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'atBox',
  props: {
    title:{
      type: String,
    },
    width:{
      default: '480px',
      type: String,
    },
    height:{
      default: '440px',
      type: String,
    }
  },
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
@import "src/assets/less/themeDark/showBox.less";
  .slot {
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .bottom-box {
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 100%;
    .p-box {
      width: 10px;
      height: 3px;
      background-color: @miniBoxColor;
    }
    .bottom-line {
      width: calc(100% - 70px);
      height: 1px;
      background-color: @bottomLineColor;
    }
  }

  .k-line {
    width: 52px;
    height: 1px;
    background-color: @topMiniLeftColor;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
  .k-icon {
    width: 20px;
    height: 3px;
    position: absolute;
    bottom: -1px;
    right: -20px;
  }
  .k {
    width: 0;
    height: 0;
    border-bottom: 38px solid @titleBoxColor;
    border-right: 38px solid transparent;
  }
  .title-box {
    width: 66%;
    height: 38px;
    //background-color: rgba(red,1);
    background-color: @titleBoxColor;

    img {
      width: 20px;
      height: 20px;
      margin-left: 15px
    }
    .title {
      margin-left: 18px;
      font-size: 23px;
      color:@sBoxTitleColor;
      font-family: 'douyu';
      position: relative;
      top: 2px;
      letter-spacing: 3px;
      font-weight: 400;
      //text-shadow: 0px 0px 9px rgba(21, 154, 255, 1);
      text-shadow: 0px 0px 9px rgba(21, 154, 255, 1);
      mix-blend-mode: normal;
    }

  }
  .at-top-box {
    width: calc(100% - 31px);
    height: 38px;
    position: relative;
    border-bottom: 1px solid @toplineColor;
    margin-bottom: 18px;
  }
  .at-box {
    width: 100%;
    height: 100%;
    //background: rgba(@sBoxBgd, 0.3);
    background-color: @sBoxBgd;

    background-blend-mode: normal;
    backdrop-filter: blur(5px);
    mix-blend-mode: normal;
    padding: 12px;
    position: relative;
  }
</style>
