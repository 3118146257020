<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  mounted() {
    document.title = "绵阳市安州区智慧果园大数据应用分析服务平台"
  },
  methods:{
  }
}
</script>
<style lang="less">
  @import "assets/css/common.css";
  @import "assets/css/jiaobiao.css";

  .scrollbar {
    margin: 0 auto;
  }
  .app::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
  }
  .app::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background   : #aaa;
  }
  .app::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 8px;
    background   : #FFFFFF;
  }
</style>
